@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Sono:wght@500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to right bottom, slate, purple); */

  background-color: rgb(0, 0, 0);
  background-image: radial-gradient(at 78% 25%, rgb(0, 0, 0) 0, transparent 28%),
    radial-gradient(at 22% 84%, rgb(0, 0, 0) 0, transparent 34%),
    radial-gradient(at 49% 48%, rgb(0, 0, 0) 0, transparent 100%),
    radial-gradient(at 75% 79%, rgb(112, 26, 117) 0, transparent 71%),
    radial-gradient(at 18% 29%, rgb(49, 46, 129) 0, transparent 46%);
  color: white;
}

h1 {
  font-family: "Poppins", sans-serif;
}

a {
  @apply transition-all ease-in-out hover:text-2xl hover:cursor-pointer;
}
li {
  @apply text-lg font-semibold;
}
.container {
  @apply bg-slate-900 bg-opacity-20 text-center  rounded-md mx-auto transition-all ease-in-out;
}

.subText {
  font-family: "IBM Plex Sans", sans-serif;
  @apply text-center;
}
.subHeading {
  @apply text-xl pt-6 w-[200px] mx-auto border-b-2 pb-2 mb-4 font-semibold  hover:font-bold hover:w-[250px] transition-all ease-in-out ;
}
.emSlide {
  @apply flex-[0_0_80%];
}
.info {
  @apply container md:w-[35vw] md:h-[45vh] relative mx-auto my-6 hover:cursor-pointer;
}
.icon {
  @apply m-2 w-24 h-16 p-2 content-center;
}
.iconn {
  @apply text-3xl mx-auto;
}
